import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const MasterData = {
	Segment: lazy(() => import('../views/Segment')),
	Regional: lazy(() => import('../views/Regional')),
	Area: lazy(() => import('../views/Area')),
	Roles: lazy(() => import('../views/Roles')),
	FieldInput: lazy(() => import('../views/FieldInput')),
	Distance: lazy(() => import('../views/Distance')),
	Activity: lazy(() => import('../views/Activity')),
	ApprovalSetting: lazy(() => import('../views/ApprovalSetting')),
	DataUser: lazy(() => import('../views/DataUser')),
	Target: lazy(() => import('../views/Target')),
	SpareParts: lazy(() => import('../views/sodiq/master-data/spare-parts/index')),
};

const JourneyPlan = {
	SettingPjp: lazy(() => import('../views/SettingPjp')),
	ExportExcel: lazy(() => import('../views/ExportExcel')),
};

const Setting = {
	StructureBagian: lazy(() => import('../views/StructureBagian')),
	StructureJabatan: lazy(() => import('../views/StructureJabatan')),
};

const Reservation = {
	Reservation: lazy(() => import('../views/sodiq/reservation/index')),
};

const Validation = {
	Requester: lazy(() => import('../views/sodiq/validations/requester/index')),
	Final: lazy(() => import('../views/sodiq/validations/final/index')),
};

const Approval = {
	AprovalReject: lazy(() => import('../views/handri/approvalReject/index')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.SettingPjp />,
		index: 'SettingPjp',
		exact: true,
	},
	{
		path: null,
		element: <JourneyPlan.ExportExcel />,
		index: 'ExportExcel',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Segment />,
		index: 'segment',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Regional />,
		index: 'regional',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Area />,
		index: 'area',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Roles />,
		index: 'role',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.FieldInput />,
		index: 'fieldInput',
		exact: true,
	},
	{
		element: <MasterData.Distance />,
		index: 'distance',
		exact: true,
	},
	{
		element: <MasterData.Activity />,
		index: 'activity',
		path: null,
	},
	{
		element: <MasterData.ApprovalSetting />,
		index: 'ApprovalSetting',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.DataUser />,
		index: 'user',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Target />,
		index: 'Target',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureBagian />,
		index: 'StructureBagian',
		exact: true,
	},
	{
		path: null,
		element: <Setting.StructureJabatan />,
		index: 'StructureJabatan',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.SpareParts />,
		index: 'SparePart',
		exact: true,
	},
	{
		path: null,
		element: <Reservation.Reservation />,
		index: 'Reservation',
		exact: true,
	},
	{
		path: null,
		element: <Validation.Requester />,
		index: 'RequesterValidation',
		exact: true,
	},
	{
		path: null,
		element: <Validation.Final />,
		index: 'FinalValidation',
		exact: true,
	},
	{
		path: null,
		element: <Approval.AprovalReject />,
		index: 'ApprovalReject',
		exact: true,
	},
];
export default contents;
